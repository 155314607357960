import React from 'react';
import { Grid, TextField, MenuItem } from '@material-ui/core';
import CustomSelects from '../CustomSelects/CustomSelects';

import MaskedInput from 'react-text-mask';

const birthdate = (props) => {
    const { inputRef , ...other} = props;
  
    return (
    <MaskedInput
        {...other}
        ref={ref => {
            inputRef(ref ? ref.inputElement : null);
        }}
        mask={[/[0-3]/, /\d/, '/', /[0-1]/ ,/\d/,'/', /\d/, /\d/, /\d/, /\d/]}
        placeholderChar={'\u2000'}
    />
    )
  }

const InfoStep = (props) => (
    <Grid >
    <TextField 
        id="user_name"
        label="Nome"
        margin="dense"
        fullWidth
        autoComplete="off"
        onChange={props.changeData('name')}
        style={{marginTop: 20, marginBottom: 20}}
        autoFocus
        defaultValue={props.data.name}
    />
    <TextField 
        id="user_birthdate"
        label="Data de Nascimento"
        margin="dense"
        fullWidth
        autoComplete="off"
        onChange={props.changeData('birthdate')}
        style={{marginBottom: 40}}
        InputProps={{
            inputComponent: birthdate
        }}
        defaultValue={props.data.birthdate}
    />
    <Grid style={{marginBottom: 40}}>
         <CustomSelects 
            value={props.data.sex} 
            change={props.changeData('sex')}
            label={"Selecione seu Gênero"}
            >
                <MenuItem value={'M'}>Masculino</MenuItem>
                <MenuItem value={'F'}>Feminino</MenuItem>
        </CustomSelects>
    </Grid>
</Grid>
);

export default InfoStep;
