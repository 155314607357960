import React from 'react';
import { Grid, Typography, Avatar } from '@material-ui/core';

const SimpleInfo = (props) => (
    <Grid container>
        <Grid item xs={2}>
            <Avatar alt={props.imgAlt} src={props.url}>
                {props.title[0]}
            </Avatar>
        </Grid>
        <Grid item xs={8}>
            <Typography variant="body2">
                {props.title}
            </Typography>
            <Typography variant="caption">
                {props.subtitle}
            </Typography>
        </Grid>
    </Grid>

);

export default SimpleInfo;
