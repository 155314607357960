import React, { Component } from 'react';
import { Switch, ListItem, List, ListItemText, ListItemSecondaryAction, Typography} from '@material-ui/core';
import { get_settings, set_settings } from '../../Services/SettingsService';

export default class Settings extends Component {
  oneSignal = [];
  constructor() {
    super();
    this.state = {
      expose: false,
      notification: false
    }

    this.OneSignal = window.OneSignal || [];


  }

  async componentDidMount() {
    
    let response = await get_settings();
    let settings = await response.json();
    this.OneSignal.isPushNotificationsEnabled().then( isEnabled => {
      if (isEnabled)
        this.setState({notification: true})
    });

    this.setState({
      expose: settings.expose ? true : false,
    })
  }

  changeExpose = (e) => {
    let expose = e.target.checked;
    this.setState({expose}, async () => {
      let response = await set_settings(this.state);
      
      if(response.status !== 204)
        this.setState({expose: !expose});
    });

  }

  changeNotification = (e) => {
    let value = e.target.checked;
    console.log("Primeiro: ",value);
    if(value) {
      console.log("Segundo: ",value);
      this.OneSignal.registerForPushNotifications();
    } 
  }

  render() {
    return (
      <div style={{marginTop:"1em"}} className="settings">
          <List >
            <ListItem  style={{backgroundColor: "#393939"}}>
              <ListItemText disableTypography secondary={
                 <Typography variant="body2">
                 Perfil público
               </Typography>
              } />
              <ListItemSecondaryAction>
              <Switch
                onChange={this.changeExpose}
                checked={this.state.expose}
              />
            </ListItemSecondaryAction>
            </ListItem>
            <ListItem  style={{backgroundColor: "#393939"}}>
              <ListItemText disableTypography secondary={
                 <Typography variant="body2">
                 Notificação
               </Typography>
              } />
              <ListItemSecondaryAction>
              <Switch
                onChange={this.changeNotification}
                checked={this.state.notifications}
              />
            </ListItemSecondaryAction>
            </ListItem>
          </List>
      </div>
    )
  }
}
