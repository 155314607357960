import React, { Component } from 'react';
import FormBox from '../Login/FormBox';
import PasswordForm from '../Password/PasswordForm';
import BackButton from '../BackButton/BackButton';


export default class Password extends Component {
  render() {
    return (
      <div className="password">
        <BackButton history={this.props.history}/>
        <FormBox>
          <PasswordForm />
        </FormBox>
      </div>
    )
  }
}
