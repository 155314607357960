import { base_url } from "./Base";
import { createBrowserHistory  } from "history";

export const free_schedule = (scheduleid, date, serviceid) => {
    return fetch(`${base_url}schedule/${scheduleid}/free_schedule?date=${date}&service=${serviceid}`,{
      headers: {
        "Content-Type" : "application/json",
        "Accept" : "application/json",
        "Authorization" : localStorage.getItem("token")        
      },
      method: "GET"
    }).then(response => {
      if (response.status === 401) {
          const history = new createBrowserHistory()
          localStorage.removeItem('token');
          localStorage.removeItem('complete_profile');
          localStorage.removeItem('consent');
          history.go('/');
      }
      return response
    });
}

export const scheduling_item = (scheduleid, customer_id, start, service) => {
   return fetch(`${base_url}schedule/${scheduleid}/scheduling_item`,{
      headers: {
        "Content-Type" : "application/json",
        "Accept" : "application/json",
        "Authorization" : localStorage.getItem("token")        
      },
      method: "POST",
      body: JSON.stringify({
        customer_id,
        schedulings: {
          start,
          service
        }
      })
    });
} 

export const list_schedule_of_service = (serviceid,establishmentid) => {
  return fetch(`${base_url}services/${serviceid}/establishment/${establishmentid}`,{
      headers: {
        "Content-Type" : "application/json",
        "Accept" : "application/json",
        "Authorization" : localStorage.getItem("token")        
      },
      method: "GET"
    });
}

export const my_schedules = async () => {
  return await fetch(`${base_url}my_schedules`,{
      headers: {
        "Content-Type" : "application/json",
        "Accept" : "application/json",
        "Authorization" : localStorage.getItem("token")        
      },
      method: "GET"
    });
}

