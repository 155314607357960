import React, { PureComponent } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { format } from 'date-fns';
import { get_working_time } from '../../Services/WorkingTime';
import { free_schedule, scheduling_item } from '../../Services/Schedule';
import CustomCalendar from '../Calendar/CustomCalendar';
import ListSelectItem from '../SelectItem/ListSelectItem';
import Blocks from '../Blocks/Blocks';
import { get_user } from '../../Auth/Auth';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { create_desired_time } from '../../Services/Desirable';



class CalendarSchedule extends PureComponent {

    state = {
        selectedDate: new Date(),
        schedules: [],
        ableDays: [],
        selectedSchedule: null,
        active: null,
        customerid: null,
        userid: null,
        desirable_day: [],
        selectedDesirable: null,
        desired: false,
        loading: true

    }

    constructor(props) {
        super(props);
        this.id = props.match.params.id;
        this.sid = props.match.params.sid;
    }

    async componentDidMount() {
        let date = format(this.state.selectedDate, 'yyyy-MM-dd');
        let user = await get_user();
            user = await user.json();
            // console.log(user);
        let customerid = user.customer.id;
        let userid = user.user.id;
        this.setState({
            loading: true
        })
        get_working_time(this.id).then(res => res.json().then(data => {
            let ableDays = data.map(working_time => {
                return working_time.day_of_week;
            })
            free_schedule(this.id, date, this.sid).then(res => res.json().then(data => {
                this.setState({ schedules: data.schedule_free, ableDays, customerid, userid, desirable_day: data.desired_schedules, desired: data.available_desired, loading: false })
            }))
        }))

    }

    loadingComponent = () => {
        return <Grid align="center"><CircularProgress style={{display: 'flex'}} color="secondary" /></Grid>
    }

    handleDateChange = date => {
        let format_date = format(date, 'yyyy-MM-dd');

        this.setState({ selectedDate: date, loading: true});

        free_schedule(this.id, format_date, this.sid).then(res => res.json().then(data => {
            this.setState({ schedules: data.schedule_free,selectedDate: date, active: null, selectedSchedule: null, desirable_day: data.desired_schedules, desired: data.available_desired, loading: false })
        }))
    };

    selectSchedule = (value, index) => {
        this.setState({
            selectedSchedule: value,
            active: index
        })
    }

    disable = (date) => {
        let disable = [0, 1, 2, 3, 4, 5, 6];
        let { ableDays } = this.state;

        if (ableDays.length) {
            disable = disable.filter(dis => {
                return !ableDays.includes(dis);
            })
            return disable.includes(date.getDay());
        }

        return false;

    }

    schedule = () => {
        let date = format(this.state.selectedDate, 'yyyy-MM-dd') + ' ' + this.state.schedules[this.state.selectedSchedule];
        scheduling_item(this.id, this.state.customerid, date, this.sid).then(res => {
            if(res.status === 201) {
                this.props.history.push('/my_schedules', { success: true});
            }
        });
    }

     createNotify = async () => {
        let date = format(this.state.selectedDate, 'yyyy-MM-dd') + ' ' + this.state.desirable_day[this.state.selectedDesirable];
        let data = {
            userid: this.state.userid,
            serviceid: this.sid,
            start_time: date
        }

        await create_desired_time(this.id, data);
    }
    
    render() {
        const { desired , selectedDate, selectedSchedule, schedules, desirable_day, selectedDesirable, loading } = this.state;
        
        const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || "#01bf9b"
        const secondaryColor = process.env.REACT_APP_SECONDARY_COLOR || "#fff"
        let now = new Date();
        let date =  now.setDate(now.getDate() + 7);
        return (
            <div style={{marginBottom: 70}}>
                <CustomCalendar maxDate={date} selected={selectedDate} changeDate={this.handleDateChange} />
              {schedules.length > 0 && desired === true  ? (
                   <Grid container>
                    <Grid item xs={12}>
                        <Blocks >
                        <Typography align="center" variant="subtitle1">
                            Horários Disponíveis
                        </Typography>
                            {!loading ? (
                            <>
                                <ListSelectItem 
                                defaultBackground={secondaryColor}
                                color={secondaryColor}
                                background={primaryColor}
                                list={schedules}
                                selected={selectedSchedule}
                                select={(selectedSchedule) => () => this.setState({selectedSchedule}) } 
                                />
                                <Button onClick={this.schedule} 
                                    disabled={selectedSchedule === null} 
                                    fullWidth 
                                    variant="outlined" 
                                    size="small" 
                                    style={{color: selectedSchedule !== null ? primaryColor : ""}}>
                                    Agendar {schedules[selectedSchedule]}
                                </Button>
                            </>
                            ) : this.loadingComponent()}
                        </Blocks>
                    </Grid>
                   </Grid>
                   
              ) : (
                <Grid container>
                    <Grid item xs={12}>
                        <Blocks>
                           <Grid style={{padding: 10}}>
                            {!loading ? (
                                <Typography align="center" variant="body2">
                                    Aaah que pena, não temos mais horários disponíveis para o dia selecionado 😢
                                </Typography>
                            ) : this.loadingComponent() }
                           </Grid>
                        </Blocks>
                    </Grid>
                </Grid>
              )}

               

            </div>
        )
    }
}

export default CalendarSchedule;