import React, { Component } from 'react';
import Schedule from './Schedule';
import { format } from 'date-fns';
import Blocks from '../Blocks/Blocks';
import MenuItem from '@material-ui/core/MenuItem';
import { get_services } from '../../Services/Services';
import {list_schedule_of_service} from '../../Services/Schedule';
import CustomSelects from '../CustomSelects/CustomSelects';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';


export default class Scheduling extends Component {
    state = {
        services: [],
        schedules: [],
        selected: 0
    }

    componentDidMount() {
        let { id } = this.props.match.params;
        get_services(id).then(res => res.json().then(data => {
            this.setState({services: data})
        }))
        
    }

    changeService = (e) => {
        let { value } = e.target;
        let { id } = this.props.match.params;
        this.setState({selected: value});
        if(value === 0) {
            this.setState({
                schedules: []
            })
        } else {
            list_schedule_of_service(value,id).then(res => res.json().then(data => {
                this.setState({schedules: data});
            }));
        }
    }
    
    formatTime = (t) => {
        let today = new Date(`${format(new Date(), 'yyyy/MM/dd')} ${t}`);
        
        let hours = format(today, 'H');
        let minutes = format(today, 'm');
        if(hours === '0') {
           let minutes_text = minutes > 1 ? 'minutos' : 'minuto'
            return `${minutes} ${minutes_text}`
        }

        if(minutes === '0') {
            let hour_text = hours > 1 ? 'horas' : 'hora'
            return `${hours} ${hour_text} `
        }

        return `${hours}h ${minutes}m`
        
    }

    render() {
        return (
          <div>
            
            <Blocks>
                <CustomSelects 
                    value={this.state.selected} 
                    change={this.changeService}
                    label={"Selecione um serviço"}
                    >
                        {this.state.services.map((item,index) => 
                            <MenuItem key={item.id} value={item.id}>{item.service_name}</MenuItem>
                        )}
                </CustomSelects>
                
            </Blocks>
            {
                this.state.selected !== 0 ?
                    <Typography style={{marginLeft: '10pt', marginTop:'20pt'}} variant="subtitle2">
                    Profissionais disponíveis
                </Typography> :
                null
            }
            <br />
                    {this.state.schedules.map(schedule => {
                        let time = this.formatTime(schedule.time);
                        return (
                            <Link key={schedule.sid} style={{textDecoration:"none"}} 
                                    to={`/services/${schedule.sid}/schedule/${schedule.id_schedule}`}>
                                <Blocks>
                                    <Schedule 
                                        avatar={schedule.picture}
                                        title={schedule.worker}
                                        subtitle={`R$ ${schedule.price}`}
                                        detail={time}
                                    />
                            </Blocks>
                           </Link>
                        )
                    }
                    )}

                
          </div>
         
        )
      }
}