import React, { Component } from 'react';

import CustomBottomNavigation from '../CustomBottomNavigation/CustomBottomNavigation';
import CalendarSchedule from '../CalendarSchedule/CalendarSchedule';
import Header from '../Header/Header';
import { Grid } from '@material-ui/core';

export default class Schedule extends Component {

  render() {
    return (
      <Grid >
        <Header title="Selecione um horário" backbutton={this.props.history} />
        <CalendarSchedule {...this.props} />
        <CustomBottomNavigation />
      </Grid>

    )
  }
}