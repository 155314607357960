import React, { Component } from 'react';
import Icon from '@material-ui/core/Icon';


class BackButton extends Component {

    render() {
        return (
            <Icon onClick={() => this.props.history.goBack()}>arrow_back</Icon>
        )
    }
}

export default BackButton;