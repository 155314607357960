import { base_url } from "./Base";
import { createBrowserHistory  } from "history";

export const get_services = (companyid) => {

    return fetch(`${base_url}company/${companyid}/services`,{
      headers: {
        "Content-Type" : "application/json",
        "Accept" : "application/json",
        "Authorization" : localStorage.getItem("token")        
      },
      method: "GET"
    }).then(response => {
      if (response.status === 401) {
          const history = new createBrowserHistory()
          localStorage.removeItem('token');
          localStorage.removeItem('complete_profile');
          localStorage.removeItem('consent');
          history.go('/');
      }
      return response
  });
}

