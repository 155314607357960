import React from 'react';
import Blocks from '../Blocks/Blocks';
import {Grid, Typography} from '@material-ui/core';

const SelectItem = (props) => {
    let { selected } = props;
    return (
        <Grid item xs={2} onClick={props.select(props.index)}>
             <Blocks background={selected ? props.background : props.defaultBackground}>
                <Typography style={{color: selected ? props.color : props.defaultColor}} align="center" variant="caption">{props.label}</Typography>
            </Blocks>
        </Grid>
)};

export default SelectItem;
