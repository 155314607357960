import React from 'react';
import { BasePicker, Calendar } from 'material-ui-pickers';
import {Paper} from '@material-ui/core';

const CustomCalendar = (props) => (
    <BasePicker value={props.selected} onChange={props.changeDate}>
    {({
        date,
    }) => (
            <div>
                <div className="picker">
                    <Paper style={{ overflow: 'hidden' }}>
                        <Calendar
                            // shouldDisableDate={props.disableDays}
                            date={date}
                            maxDate={props.maxDate}
                            onChange={props.changeDate} 
                            disablePast
                            />
                    </Paper>
                </div>

            </div>
        )}
    </BasePicker>
);

export default CustomCalendar;
