import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import MaskedInput from 'react-text-mask';

const cellphone = (props) => {
    const { inputRef , ...other} = props;
  
    return (
    <MaskedInput
        {...other}
        ref={ref => {
            inputRef(ref ? ref.inputElement : null);
        }}
        mask={['(',/\d/, /\d/, ')', /\d/ ,/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
        placeholderChar={'\u2000'}
    />
    )
  }

const ContactStep = (props) => {
   return (
          <Grid >
            <TextField 
                id="user_mail"
                label="Email"
                margin="dense"
                fullWidth
                autoComplete="off"
                onChange={props.changeData('email')}
                style={{marginTop: 40, marginBottom: 40}}
                autoFocus
                defaultValue={props.data.email}
                error={props.data.email_error}
                helperText={props.data.email_error ? 'O email informado já existe' : ''}
            />
            <TextField 
                id="user_phone"
                label="Celular"
                margin="dense"
                fullWidth
                autoComplete="off"
                onChange={props.changeData('cellphone')}
                style={{marginBottom: 40}}
                InputProps={{
                    inputComponent: cellphone
                }}
                defaultValue={props.data.cellphone}
                error={props.data.telephone_error}
                helperText={props.data.telephone_error ? 'O celular informado já existe' : ''}
            />
        </Grid>
   )
};

export default ContactStep;
