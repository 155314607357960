import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';

const styles = {
  bottomNavStyle: {
      position: 'fixed',
      left: '0px',
      bottom: '0px',
      height: '50px',
      width: '100%',
      zIndex: '100',
  },
  selected: {}
};


class CustomBottomNavigation extends Component {
  state = {
    value: this.props.start
  }
  handleChange = (e,value) => {
    this.setState({
      value
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <BottomNavigation
         className={classes.bottomNavStyle}
         value={this.state.value}
         onChange={this.handleChange}
         showLabels
       >
       <BottomNavigationAction  
       component={Link}
       to="/app"
       classes={{
        root: classes.actionItemStyles,
        selected: classes.selected
        }} 
        value={0}
       label="Agendar" icon={<Icon>schedule</Icon>} />
       <BottomNavigationAction  
        component={Link}
        to="/my_schedules"
        classes={{
          root: classes.actionItemStyles,
          selected: classes.selected
          }} 
       value={1}
       label="Agendamentos" icon={<Icon>date_range</Icon>} />
        <BottomNavigationAction  
        component={Link}
        to="/profile"
        classes={{
          root: classes.actionItemStyles,
          selected: classes.selected
          }} 
       value={2}
       label="Perfil" icon={<Icon>person</Icon>} />
       

   </BottomNavigation>
    )
  }
}

export default withStyles(styles)(CustomBottomNavigation);