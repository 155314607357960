import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import ContactStep from './ContactStep';
import InfoStep from './InfoStep';
import PasswordStep from './PasswordStep';
import Button from '@material-ui/core/Button';
import { Validator } from '../../Helpers/Validator';
import { register, exist } from '../../Auth/Auth';
import { Redirect } from "react-router-dom";


class RegisterForm extends Component {
  constructor() {
    super();
    this.state = {
      step: 1,
      email: "",
      cellphone: "",
      birthdate: "",
      formated_birthdate: "",
      name: "",
      sex: "0",
      password: "",
      password_confirmation: "",
      success: false,
      data: {}
    }
    this.firstStep = new Validator();
    this.secondStep = new Validator();
    this.thirdStep = new Validator();
  }

  next = () => {
    let step = this.state.step + 1;
    this.setState({
      step
    })
  }

  previous = () => {
    let step = this.state.step - 1;
    this.setState({
      step
    })
  }

  backToHome = () => {
    this.props.goBack();
  }

  validate = async () => {
    const response = await exist(this.state);
    if (response.status === 200) {
      const exist = await response.json();

      if (exist.email || exist.telephone) {
        this.setState({
          email_error: exist.email,
          telephone_error: exist.telephone
        })
        return false;
      }
      this.setState({email_error: false, telephone_error: false});
      this.next();
    }
  }

  getData = (name) => (e) => {
    let value = e.target.value.trim();
    if (name === 'cellphone') {
      value = e.target.value.replace("(","").replace(")","").trim();
    }

    if (name === 'birthdate') {
      const birthdate = value.split('/');
      const formated_birthdate = `${birthdate[2]}-${birthdate[1]}-${birthdate[0]}`;
      this.setState({
        formated_birthdate
      })
      console.log(formated_birthdate);
      value = e.target.value.replace(/\//g, '').trim();
    }
    
    this.setState({
      [name]: value.toString()
    })
  }

  done = async () => {
    const response_register = await register(this.state);

    if (response_register.status === 201) {
      const data = await response_register.json();
      this.setState({
        success: true,
        data
      })
    }
  }
  
  render() {
    if(this.state.success) return <Redirect to={{ pathname: "/success", state: this.state.data }}/>
    this.firstStep.setValues([
      {value:this.state.email, email: true, required: true},
      {value:this.state.cellphone, min: 11, max:11 , required:true}
    ])

    this.secondStep.setValues([
      {value: this.state.name, required:true},
      {value: this.state.birthdate, min: 8, required: true}
    ])

    this.thirdStep.setValues([
      { value: this.state.password, required: true, min: 7},
      { value: this.state.password_confirmation, equals: this.state.password },
    ])

    switch(this.state.step) {
      case 1: return (
        <Grid container  style={{padding: 10}}>
          <ContactStep data={this.state} changeData={this.getData} />
        <Grid style={{paddingRight: 5}} item xs={6}>
         <Button  fullWidth onClick={this.backToHome} variant="contained" size="medium">
            Voltar
          </Button>
        
        </Grid>
        <Grid style={{paddingLeft: 5}} item xs={6}>
         <Button disabled={!this.firstStep.check()} fullWidth onClick={this.validate} variant="contained" size="medium" color="secondary">
            Próximo
          </Button>
        </Grid>
        </Grid>
      )
      case 2: return (
        <Grid container  style={{padding: 10}}>
        <InfoStep data={this.state} changeData={this.getData} />
      <Grid style={{paddingRight:5}} item xs={6}>
      <Button  fullWidth onClick={this.previous} variant="contained" size="medium">
          Voltar
        </Button>
      </Grid>
      <Grid style={{paddingLeft:5}} item xs={6}>
        
        <Button disabled={!(this.secondStep.check() && this.state.sex !== '0')} fullWidth onClick={this.next} variant="contained" size="medium" color="secondary">
          Próximo
        </Button>
      </Grid>
      </Grid>
      )
      case 3: return (
        <Grid container style={{padding: 10}}>
        <PasswordStep data={this.state} changeData={this.getData} />
      <Grid item xs={6}>
      <Button style={{paddingRight: 5}}  fullWidth onClick={this.previous} variant="contained" size="medium">
          Voltar
        </Button>
      </Grid>
      <Grid style={{paddingLeft: 5}} item xs={6}>
        
        <Button disabled={!(this.thirdStep.check())} fullWidth onClick={this.done} variant="contained" size="medium" color="secondary">
          Finalizar
        </Button>
      </Grid>
      </Grid>
      )
      default: return null
    }
  }
}

export default RegisterForm;
