import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { Link } from 'react-router-dom';

export default class HeaderMenu extends Component {
    state = {
        anchorEl: null
    }
  
    openMore = event => {
      this.setState({ anchorEl: event.currentTarget });
    };
  
    closeMore = () => {
      this.setState({ anchorEl: null });
    };
    render() {
        const { anchorEl } = this.state;
        const isMenuOpen = Boolean(anchorEl);
        return (
        <>
            <IconButton onClick={this.openMore}>
                <Icon >
                    more_vert
                </Icon>
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={isMenuOpen}
                onClose={this.closeMore}
            >
            {this.props.items.map((item, index) => <MenuItem key={index} onClick={this.closeMore} component={Link}
                                            to={item.link}>{item.title}</MenuItem>)}
        </Menu>
        </>
        )

    }
}