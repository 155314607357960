import React, {Component} from 'react';
import { Button, Paper, Grid, TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import MaskedInput from 'react-text-mask';
import { Redirect } from 'react-router-dom';
import { format } from 'date-fns';
import { get_user, logout } from '../../Auth/Auth';
import { Validator } from '../../Helpers/Validator';
import { update_user } from '../../Services/Customers';

import db from '../../Services/Db';

const telephone = (state) => {
    const { inputRef , ...other} = state;
    
    return (
    <MaskedInput
        {...other}
        ref={ref => {
            inputRef(ref ? ref.inputElement : null);
        }}
        mask={['(',/\d/, /\d/, ')', /\d/ ,/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
        placeholderChar={'\u2000'}
    />
    )
  }

  const birthdate = (state) => {
    const { inputRef , ...other} = state;
  
    return (
    <MaskedInput
        {...other}
        ref={ref => {
            inputRef(ref ? ref.inputElement : null);
        }}
        mask={[/\d/, /\d/, '/', /\d/ ,/\d/,'/', /\d/, /\d/, /\d/, /\d/]}
        placeholderChar={'\u2000'}
    />
    )
  }

export class UserProfile extends Component {

      state = {
        email: '',
        name: '',
        birthdate: '',
        telephone: '',
        sex: '',
        ready: 0
      }

      constructor(props) {
        super(props);
        this.validator = new Validator();
      }
      async componentDidMount() {
        db.transaction('rw', db.profile, async () => {
            const result = await db.profile.where('id').equals(1).first();
            
            if (result !== undefined) {
                const { profile } = result;
                this.setState({
                    current_name: profile.customer.name,
                    current_email: profile.user.email,
                    current_birthdate: format(new Date(`${profile.customer.birthdate.replace(/-/g, "/")} 00:00:00`),'ddMMyyyy'),
                    current_telephone: profile.customer.telephone,
                    current_sex: profile.customer.sex,
                    name: profile.customer.name,
                    email: profile.user.email,
                    birthdate: format(new Date(`${profile.customer.birthdate.replace(/-/g, "/")} 00:00:00`),'ddMMyyyy'),
                    formated_birthdate: format(new Date(`${profile.customer.birthdate.replace(/-/g, "/")}`), 'yyyy-MM-dd'),
                    telephone: profile.customer.telephone,
                    sex: profile.customer.sex,
                    ready: 1
                  });
            }
        });

        let response = await get_user();
        let {user, customer} = await response.json();

        if (response.status === 200) {
            
            db.transaction('rw', db.profile, async () => {
                const result = await db.profile.where('id').equals(1).toArray();

                if(result.length) {
                    await db.profile.where('id').equals(1).modify({profile: {user, customer}});
                } else {
                    await db.profile.add({id: 1,profile: {user, customer}});
                }
            });

            this.setState({
              redirect: false,
              current_name: customer.name,
              current_email: user.email,
              current_birthdate: format(new Date(`${customer.birthdate.replace(/-/g, "/")} 00:00:00`),'ddMMyyyy'),
              current_telephone: customer.telephone,
              current_sex: customer.sex,
              name: customer.name,
              email: user.email,
              birthdate: format(new Date(`${customer.birthdate.replace(/-/g, "/")} 00:00:00`),'ddMMyyyy'),
              formated_birthdate: format(new Date(`${customer.birthdate.replace(/-/g, "/")}`), 'yyyy-MM-dd'),
              telephone: customer.telephone,
              sex: customer.sex,
              ready: 1
            });
        }
      }

      changeData = (tag) => (e) => {
        let value = e.target.value;

        if(tag === 'birthdate') {
            let date = value.split('/');
            let formated_birthdate = `${date[2]}-${date[1]}-${date[0]}`;
            this.setState({
                formated_birthdate
            });
        }
        if(tag === "birthdate" || tag === "telephone") {
            value = value.replace(/[\s.\-()/]/g, "");
        }
        this.setState({
            [tag]: value
        });
      }

      isChanged = () => {
          return this.state.current_name !== this.state.name ||
                 this.state.current_birthdate !== this.state.birthdate ||
                 this.state.current_telephone !== this.state.telephone ||
                 this.state.current_sex !== this.state.sex;
      }

      isValid = () => {
        return this.validator.check();
      }

      submit = async (e) => {
          e.preventDefault();
          let { name , sex , formated_birthdate, telephone} = this.state;
          let response_user = await update_user({
            name, sex, birthdate: formated_birthdate, telephone
          });
          
          if(response_user.status === 200) {
              this.setState({
                  current_birthdate: this.state.birthdate,
                  current_name: this.state.name,
                  current_telephone: this.state.telephone,
                  current_sex: this.state.sex
              })
          }
      }

      logout = async () => {
          const response = await logout();

          if (response.status === 200) {
              this.setState({
                  redirect: true
              })
          }
      }

    render() {
        if(this.state.redirect) return <Redirect to="/" />
        this.validator.setValues([
            { value: this.state.name, required: true, min: 3 },
            { value: this.state.birthdate, required: true, min:8 },
            { value: this.state.telephone, required:true, min: 11 }
        ]);
        if(!this.state.ready) return null; 
        return (
            <form onSubmit={this.submit} style={{margin:10}} >
            <Paper style={{ padding:10}} elevation={1}>
            <Grid container >
               <Grid item xs={12}>
                    <TextField
                        id="user_email"
                        label="Email"
                        margin="dense"
                        fullWidth
                        value={this.state.email}
                        autoComplete="off"
                        disabled
                    />
               </Grid>
               <Grid item xs={7}>
                     <TextField
                        id="user_name"
                        label="Nome"
                        margin="dense"
                        fullWidth
                        autoComplete="off"
                        defaultValue={this.state.name}
                        onChange={this.changeData('name')}

                    />
                     <TextField
                        id="user_birthdate_register"
                        label="Data nascimento"
                        margin="dense"
                        fullWidth
                        autoComplete="off"
                        defaultValue={``}
                        onChange={this.changeData("birthdate")}
                        value={this.state.birthdate}
                        InputProps={{
                         inputComponent: birthdate
                        }}
                    />
                      <TextField
                            id="user_telephone"
                            label="Celular"
                            margin="dense"
                            fullWidth
                            autoComplete="off"
                            defaultValue={` `}
                            value={this.state.telephone}
                            onChange={this.changeData('telephone')}
                            InputProps={{
                              inputComponent: telephone
                            }}
                        />
               </Grid>
               <Grid item xs={1} />
               <Grid item xs={3}>
                <FormControl style={{marginTop:"20px"}} component="fieldset">
                    <FormLabel component="legend">Gênero</FormLabel>
                        <RadioGroup
                        aria-label="gender"
                        name="gender"
                        value={this.state.sex ? this.state.sex : 'F'}
                        onChange={this.changeData('sex')}
                        >
                        <FormControlLabel value="M" control={<Radio />} label="Masculino" />
                        <FormControlLabel value="F" control={<Radio />} label="Feminino" />
                        </RadioGroup>
                    </FormControl>
               </Grid>
    
            </Grid>
            </Paper>
            <Button disabled={!this.isValid() || !this.isChanged()} style={{marginTop:"1em"}}  type="submit" fullWidth variant="contained" size="medium" color="secondary">
                Salvar Alterações
            </Button>
            <Button onClick={this.logout} style={{marginTop:"1em", background: '#dc3545'}}  type="submit" fullWidth variant="contained" size="medium" color="secondary">
                Deslogar
            </Button>
            </form>
        )
    }
}

export default UserProfile;
