import { base_url } from "./Base";

export const create_desired_time = (scheduleid, body) => {
    return fetch(`${base_url}desired_time/${scheduleid}`,{
        headers: {
          "Content-Type" : "application/json",
          "Accept" : "application/json",
          "Authorization" : localStorage.getItem("token")        
        },
        method: "POST",
        body: JSON.stringify(body)
      })
}