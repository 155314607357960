import React, { PureComponent } from 'react';
import { Grid, Icon, Divider, Button, Typography } from '@material-ui/core';
import MessageBackground from '../MessageBackground/MessageBackground';
import Blocks from '../Blocks/Blocks';
import Schedule from '../SearchServices/Schedule';
import { my_schedules } from '../../Services/Schedule';
import { confirm_schedule, cancel_schedule } from '../../Services/SchedulingItem';
import ModalConfirm from '../Modal/ModalConfirm';
import ModalFullScreen from '../Modal/ModalFullScreen';
import Reschedule from './Reschedule';
import { format } from 'date-fns';
import { CustomSnackbar } from '../Snackbars/CustomSnackbar';

import db from '../../Services/Db';

export default class MySchedules extends PureComponent {
  state = {
      filter: 'A',
      my_schedule: [],
      snackBarOpen: false,
      snackBarMessage: null,
      snackBarBackground: null,
      selectedSchedule: null,
      selectedSid: null,
      selectedServiceId: null,
      modalTitle: "",
      modalText: "",
      modalConfirm: false,
      modalCancel: false,
      modalReschedule: false,
      loading: true
  }

  async componentDidMount() {
    db.transaction('rw', db.my_schedules, async () => {
        const result = await db.my_schedules.where('id').equals(1).first();
        
        if (result !== undefined) {
            this.setState({
                my_schedule: result.schedules,
                loading: false,
            });
        }
    })
    let response_my_schedule = await my_schedules();
    let my_schedule = await response_my_schedule.json();

    if (response_my_schedule.status === 200) {
        db.transaction('rw', db.my_schedules, async () => {
            const schedules = await db.my_schedules.where('id').equals(1).toArray();

            if (schedules.length) {
                await db.my_schedules.where('id').equals(1).modify({schedules: my_schedule});

            } else {
                db.my_schedules.add({id: 1, schedules: my_schedule});
            }

            this.setState({
                my_schedule,
                loading: false
            })
        })
    }
  }

  status = (state) => {
    if(state === 'S') return 'Agendado';
    else if(state === 'C') return 'Confirmado';
    else if(state === 'Z') return 'Cancelado';
    else return 'Indefinido';
  }

  buttonAction = (label,color, status = false,callback = null) => {
      let color_disabled = status ? '' : color;
      
      return (
        <Button 
            onClick={callback}
            disabled={status}
            size='small' 
            fullWidth
            style={{fontSize:"10px", color: color_disabled}}
        >
            {label}
        </Button>
      )
  }

  confirm = (id) => async () => {
    let response = await confirm_schedule(id);
    if(response.status === 200) {
        this.setState({
            my_schedule: this.state.my_schedule.map(schedule =>{
                if(schedule.id === id) schedule.status = 'C';
                return schedule;
            }),
            modalConfirm: false,
            selectedSchedule: null
        })
    }
  }

  cancel = (id) => async () => {
    let response = await cancel_schedule(id);
    if(response.status === 200) {
        this.setState({
            my_schedule: this.state.my_schedule.map(schedule =>{
                if(schedule.id === id) schedule.status = 'Z';
                return schedule;
            }),
            modalCancel: !this.state.modalCancel,
            selectedSchedule: null
        })
    }
  }

  reschedule =  ({data, message}) => {
    if(data) {
        this.setState({
            my_schedule: this.state.my_schedule.map(schedule => {
                if(schedule.id === data.id) {
                    schedule.status = data.status;
                    schedule.date = format(data.start,'dd/MM/yyyy HH:mm')
                }
    
                return schedule;
            }),
            modalReschedule: !this.state.modalReschedule,
            snackBarMessage: message,
            snackBarBackground: "#43a047",
            snackBarOpen: !this.state.snackBarOpen
        })

    } else {
        this.setState({
            modalReschedule: !this.state.modalReschedule,
            snackBarMessage: message,
            snackBarBackground: "#d32f2f",
            snackBarOpen: !this.state.snackBarOpen
            
        })
    }
  }

  openConfirm = (selectedSchedule) => () => {
      let modalTitle , modalText;
          modalTitle = 'Confirmar';
          modalText = 'Deseja confirmar seu agendamento?';

      this.setState({
          selectedSchedule,
          modalTitle,
          modalText,
          modalConfirm: !this.state.modalConfirm
      })
  }

  openReschedule = (selectedSchedule, selectedSid, selectedServiceId) => () => {
    let modalTitle;
    modalTitle = 'Reagendar';
    this.setState({
        selectedSid,
        selectedServiceId,
        selectedSchedule,
        modalTitle,
        modalReschedule: !this.state.modalReschedule
    })
  }

  openCancel = (selectedSchedule) => () => {
    let modalTitle , modalText;
    modalTitle = 'Cancelar';
    modalText = 'Deseja cancelar seu agendamento?';

    this.setState({
        selectedSchedule,
        modalTitle,
        modalText,
        modalCancel: !this.state.modalCancel
    })
  }

  closeSnackbar = () => {
      this.setState({
          snackBarMessage: null,
          snackBarOpen: !this.state.snackBarOpen
      })
  }

  render() {
    if(this.state.loading) return null;
    return (
        <Grid item xs={12} style={{marginBottom: "4em"}}>
            {this.state.my_schedule.length === 0 ? (
                <MessageBackground 
                    color="rgb(0, 0, 0, 0.35)"
                    message="Nenhum agendamento"
                    icon={<Icon style={{fontSize: 100}}>
                            sentiment_dissatisfied
                        </Icon>}
                />
            ) : 
            (
                this.state.my_schedule.map(schedule => {
                    let status = this.status(schedule.status);

                    return (
                    <Blocks key={schedule.id}>
                        <Typography align="center" variant="body2">{schedule.company}</Typography>
                        <Schedule 
                            text={status}
                            avatar={schedule.logo}
                            title={schedule.worker}
                            subtitle={`${schedule.service}`}
                            detail={schedule.date}
                        />
                        <Divider />
                       <Grid container >
                          <Grid item xs={4}  >
                            {this.buttonAction('Cancelar','#d32f2f',schedule.status === 'Z',
                                                this.openCancel(schedule.id))}
                            </Grid>
                            <Grid item xs={4} >
                            {this.buttonAction('Reagendar','#0099ff',schedule.status === 'Z',
                                                this.openReschedule(schedule.id,schedule.seid, schedule.service_schedules_id))}
                            </Grid>
                            <Grid item xs={4} >
                            {this.buttonAction('Confirmar', '#43a047',
                                            schedule.status === 'Z' || schedule.status === 'C',
                                            this.openConfirm(schedule.id)
                                            )}
                            </Grid>
                       </Grid>
                        
                    </Blocks>
                    ) 
                }
                )
            )
            }
            <ModalConfirm 
                title={this.state.modalTitle} 
                text={this.state.modalText} 
                open={this.state.modalConfirm} 
                close={() => this.setState({modalConfirm: false, selectedSchedule: null})}
                action={this.confirm(this.state.selectedSchedule)}
            />
            <ModalFullScreen
                title={this.state.modalTitle}
                text={this.state.modalText}
                open={this.state.modalReschedule}
                close={() => 
                    this.setState({
                        modalReschedule: false,
                        selectedSchedule: null, 
                        selectedServiceId: null,
                        selectedSid: null})}
                
            >
                <Reschedule 
                    scheduleid={this.state.selectedSid}
                    serviceid={this.state.selectedServiceId}
                    id={this.state.selectedSchedule} 
                    eventReschedule={this.reschedule}
                />
            </ModalFullScreen>
                <ModalConfirm 
                title={this.state.modalTitle}
                text={this.state.modalText}
                open={this.state.modalCancel}
                close={() => this.setState({modalCancel: false, selectedSchedule: null})}
                action={this.cancel(this.state.selectedSchedule)}
            />
            <CustomSnackbar 
                color={this.state.snackBarBackground}
                open={this.state.snackBarOpen}
                message={this.state.snackBarMessage}
                handleClose={this.closeSnackbar}
            />
        </Grid>
    );
  }
}

