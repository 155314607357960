import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Avatar, Typography } from '@material-ui/core';

const styles = {
  bigAvatar: {
    margin:3,
    width:50,
    height:50
  }
}

const Schedule = (props) => {
    let { title, subtitle, detail ,avatar ,classes, text } = props;
    return (
      <Grid container>
        <Grid item xs={3} >
          <Avatar src={avatar} className={classes.bigAvatar} />
        </Grid>
        <Grid item xs={4}  >
          <Typography variant="body1">
            {title}
          </Typography>
          <Typography variant="caption">
            {subtitle}
          </Typography>
          <Typography variant="caption">
            {text}
          </Typography>
        </Grid>
        <Grid item xs={5} style={{padding: "2.8px"}}  >
          <Typography align="right" variant="caption">
            {detail}
          </Typography>
          
        </Grid>
      </Grid>
    )
}

export default withStyles(styles)(Schedule);