import React, { Component } from 'react';
import LoginForm from '../Login/LoginForm';
import CustomGoogleLogin from '../Login/CustomGoogleLogin';
import CustomFacebookLogin from '../Login/CustomFacebookLogin';

class Login extends Component {

  render() {
    return (
    <div style={{paddingTop: 50, paddingLeft: 10, paddingRight: 10}} >
        <LoginForm />
        <CustomGoogleLogin />
        <CustomFacebookLogin />
    </div>
    )
  }
}

export default Login;