import React, { Component } from 'react';

import SearchServices from '../SearchServices/SearchServices';
import CustomBottomNavigation from '../CustomBottomNavigation/CustomBottomNavigation';
import Header from '../Header/Header';
export default class Scheduling extends Component {

  render() {
    return (
      <div>
        <Header title="Selecione um serviço" backbutton={this.props.history} />
        <SearchServices {...this.props} />
        <CustomBottomNavigation />
      </div>

    )
  }
}