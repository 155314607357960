import React from 'react';
import { AppBar, Toolbar,Grid ,Typography, Dialog, IconButton, Icon } from '@material-ui/core';
import Transition from '../Transition/Transition';

const secondaryColor = process.env.REACT_APP_SECONDARY_COLOR || "#fff"

const ModalFullScreen = (props) =>
(
    <Dialog
        fullScreen
        open={props.open}
        onClose={props.close}
        TransitionComponent={Transition}
    >
    <AppBar style={{background: props.colorBar}}>
        <Toolbar >
            <IconButton style={{color: secondaryColor}} onClick={props.close}>
                <Icon>close</Icon>
            </IconButton>
            <Typography style={{color: secondaryColor}}  variant="h6">{props.title}</Typography>
        </Toolbar>
    </AppBar>
    <Grid container style={{margin: "4em 0em"}}>
        {props.children}
    </Grid>
</Dialog>
);

export default ModalFullScreen;
