import React from 'react';
import { Grid, Typography } from '@material-ui/core';

// import { Container } from './styles';

const styles = {
    centerText: { textAlign: "center" }
}

const MessageBackground = (props) => (
            <Grid container 
                style={{marginTop: 190}}
            >
               <Grid style={styles.centerText} item xs={12}>
                   <Typography style={{color: props.color}}>
                       {props.icon}
                   </Typography>
               </Grid>
                <Grid style={styles.centerText} item xs={12}>
                <Typography variant="h5" style={{color: props.color}}>
                       {props.message}
                   </Typography>
                </Grid>
            </Grid>
);

export default MessageBackground;
