import { base_url } from "./Base";

export const scheduling_item_id = (itemid) => {
    return fetch(`${base_url}scheduling_item/${itemid}`,{
        headers: {
          "Content-Type" : "application/json",
          "Accept" : "application/json",
          "Authorization" : localStorage.getItem("token")        
        },
        method: "GET"
      });
}

export const reschedule = (itemid, data) => {
      return fetch(`${base_url}scheduling_item/${itemid}/reschedule`, {
        headers: {
          "Content-Type" : "application/json",
          "Accept" : "application/json",
          "Authorization" : localStorage.getItem("token")        
        },
        method: "PATCH",
        body: JSON.stringify(data)
      })
}

export const confirm_schedule = async (id) => {
  return await fetch(`${base_url}scheduling_item/${id}/confirm`,{
      headers: {
        "Content-Type" : "application/json",
        "Accept" : "application/json",
        "Authorization" : localStorage.getItem("token")        
      },
      method: "PATCH"
    });
}

export const cancel_schedule = async (id) => {
  return await fetch(`${base_url}scheduling_item/${id}/cancel`,{
    headers: {
      "Content-Type" : "application/json",
      "Accept" : "application/json",
      "Authorization" : localStorage.getItem("token")        
    },
    method: "PATCH"
  });
}