import { base_url } from "./Base";

export const get_working_time = (scheduleid) => {
    return fetch(`${base_url}schedule/${scheduleid}/working_time`,{
        headers: {
          "Content-Type" : "application/json",
          "Accept" : "application/json",
          "Authorization" : localStorage.getItem("token")        
        },
        method: "GET"
      });
  }