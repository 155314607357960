import { Grid } from '@material-ui/core';
import React, { Component } from 'react';
import MySchedules from '../MySchedules/MySchedules';
import CustomBottomNavigation from '../CustomBottomNavigation/CustomBottomNavigation';


export default class MySchedulesPage extends Component {
  render() {
    return (
      <Grid container >
          <MySchedules />
          <CustomBottomNavigation start={1} />
      </Grid>
    );
  }
}
