import React, { Component } from 'react';
import { Grid, TextField, Button, Snackbar, SnackbarContent } from '@material-ui/core';
import Header from '../Header/Header';
import { Validator } from '../../Helpers/Validator';
import { forgot_password } from '../../Auth/Auth';

export default class ForgotPassword extends Component {
    state = {
        email: '',
        redirect: false,
        message: '',
        color: '',
        alert: false
    }

    constructor(props) {
        super(props);
        this.validator = new Validator();
    }

    changeEmail = (e) => {
        const email = e.target.value;
        this.setState({
            email
        })
    }

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        this.setState({ alert: false });
    };

    send = async (e) => {
        e.preventDefault();
        
        const email = this.state.email;
        const type_user = 'CUSTOMER';

        const response = await forgot_password({
            email,
            type_user
        });

        const { message } = await response.json();

        if (response.status === 201) {
            this.setState({
                message,
                alert: true,
                email: '',
                redirect: true,
                color: '#43A047'
            })
        }

        if (response.status === 404) {
            
            this.setState({
                color: '#B00020',
                alert: true,
                message
            })
        }
        
    }
    
  render() {
    this.validator.setValues([
        { name: "email", value: this.state.email, email: true, required: true },
    ])
    return (
        <form onSubmit={this.send}>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.state.alert}
                    autoHideDuration={3000}
                    onClose={this.handleClose}
                >
                    <SnackbarContent
                        aria-describedby="client-snackbar"
                        message={this.state.message}
                        style={{ backgroundColor: this.state.color }}
                    />
                </Snackbar>
            <Header title="Recuperar Senha" backbutton={this.props.history} />
            <Grid style={{padding: 30}}>
                <TextField
                    id="user_email"
                    label="Email"
                    margin="dense"
                    fullWidth
                    autoComplete="off"
                    onChange={this.changeEmail}
                    value={this.state.email}
                />

                <Button disabled={!this.validator.check()} style={{marginTop: "20px"}} type="submit" fullWidth variant="contained" size="medium" color="secondary">
                    Enviar
                </Button>
            </Grid>
        </form>
    );
  }
}
