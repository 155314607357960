import React, { Component } from 'react';
import Bar from '../Bar/Bar';
import Typography from '@material-ui/core/Typography';
import BackButton  from '../BackButton/BackButton';
import Toolbar from '@material-ui/core/Toolbar';
import HeaderButtons from './HeaderButtons';

export default class Header extends Component {
    
    render() {
        const buttons = this.props.buttons || [];
        const backbutton = this.props.backbutton ? <BackButton history={this.props.backbutton} /> : null;
        return (
            <Bar>
                <Toolbar>
                    {backbutton}
                    <Typography style={{flexGrow:1}} variant="subtitle1" color="inherit" noWrap> 
                        {this.props.title}
                    </Typography>
                    <div>
                        <HeaderButtons buttons={buttons} />                    
                    </div>
                </Toolbar>
            </Bar>
  
        )
    }
  }