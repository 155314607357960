import React, { Component } from 'react';

import { TextField, Button, Snackbar, SnackbarContent } from '@material-ui/core';
import Header from '../Header/Header';
import { Validator } from '../../Helpers/Validator';
import { reset_password } from '../../Auth/Auth';
import { Redirect } from "react-router-dom";

export default class ResetPassword extends Component {
    state = {
        password: '',
        password_confirmation: '',
        redirect: false,
        message: '',
        color: ''
    }

    constructor(props) {
        super(props);
        this.validator = new Validator();
    }

    saveState = (name) => (e) => {
        const value = e.target.value;

        this.setState({
            [name]: value
        });
    }

    send = async (e) => {
        e.preventDefault();
        const { token } = this.props.match.params;
        const { password, password_confirmation } = this.state;
        
        const response = await reset_password({password, password_confirmation, token});
        const redirect = true

        if (response.status === 200) {
            const { message } = await response.json();
            this.setState({
                password: '',
                password_confirmation: '',
                message,
                color: '#43A047'
            });

            setTimeout(() => { this.setState({ redirect })}, 3000);
        } else {
            this.setState({
                password: '',
                password_confirmation: '',
                message: 'Não foi possível alterar a senha...',
                color: '#B00020'
            });
            setTimeout(() => { this.setState({ redirect })}, 3000);
        }
    }

  render() {
    this.validator.setValues([
        { value: this.state.password, required: true, min: 7},
        { value: this.state.password_confirmation, equals: this.state.password },
    ])
    if (this.state.redirect) return <Redirect to="/" />
    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={this.state.message}
                autoHideDuration={3000}
            >
                <SnackbarContent
                    aria-describedby="client-snackbar"
                    message={this.state.message}
                    style={{ backgroundColor: this.state.color }}
                />
            </Snackbar>
            <Header title="Redefinir Senha"/>
            <form onSubmit={this.send} style={{padding: 30}}>
                <TextField
                    id="password"
                    type="password"
                    label="Nova Senha"
                    margin="dense"
                    fullWidth
                    autoComplete="off"
                    onChange={this.saveState('password')}
                    value={this.state.password}
                />

                <TextField
                    id="confirmation_password"
                    type="password"
                    label="Confirmar Nova Senha"
                    margin="dense"
                    fullWidth
                    autoComplete="off"
                    onChange={this.saveState('password_confirmation')}
                    value={this.state.password_confirmation}
                />

                <Button disabled={!this.validator.check()} style={{marginTop: "20px"}} type="submit" fullWidth variant="contained" size="medium" color="secondary">
                    Enviar
                </Button>
            </form>
        </>
    );
  }
}
