import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';


export default class FormBox extends Component {
  render() {
    return (
      <Grid
        item
        xs={12}
        style={
          {
            background: "#363636",
            padding: "3em .9em .5em .9em",
            marginBottom: ".5em",
            marginTop: "5em"
          }
        }
      >
        {this.props.children}
      </Grid>
    )
  }
}
