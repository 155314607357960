import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import Blocks from '../Blocks/Blocks';
import SimpleInfo from '../Info/SimplesInfo';
import CustomInputSearch from '../CustomInputSearch/CustomInputSearch';
import { getEstablishments } from '../../Services/Establishments';

import db from '../../Services/Db';
export default class HomeEstablishments extends Component {

  state = {
    companies: [],
  }

  constructor(props) {
    super(props);
    
    this.search = '';
  }

  async componentDidMount() {
    db.transaction('rw', db.establishments, async () => {
      const resultdb = await db.establishments.where('id').equals(1).first();
      if(resultdb !== undefined) {
        this.setState({
          companies: resultdb.companies
        })
      }
    })

    let response_companies = await getEstablishments();
    let companies = await response_companies.json();

    if (response_companies.status === 200) {

      db.transaction('rw', db.establishments, async () => {
        const dbcompanies = await db.establishments.where('id').equals(1).toArray();
        if (dbcompanies.length) {
          await db.establishments.where('id').equals(1).modify({companies});
        } else {
          await db.establishments.add({id: 1, companies});
        }

      });
      
      this.setState({
        companies
      })
    }
  }

  openEstablishment = (value) => () => {
    alert(value);
  }

  getSearch = (e) => {
    let { value } = e.target;
    this.search = value;
  }

  clickSearch = async (e) => {
    e.preventDefault();
    let query, response_companies, companies;
    query = `?search=${this.search}`;

    response_companies = await getEstablishments(query);
    companies = await response_companies.json();

    this.setState({
      companies
    })
  }

  render() {
    let { companies } = this.state; 
    return (
        <Grid> 
            <CustomInputSearch
              inputChange={this.getSearch}
              search={this.clickSearch}
            />
            <Grid  container style={{marginTop:10}}>
              {companies.map((company, index) => 
              <Grid
              style={{textDecoration:'none'}}
              component={Link}
              to={`establishment/${company.id}/services`}
              item xs={12}  key={index}>
                <Blocks>
                   <SimpleInfo 
                    title={company.fantasy_name}
                    subtitle={company.name}
                    url={company.logo}
                   />
                </Blocks >
              </Grid>
              )}
            </Grid>
        </Grid>
    )
  }
}
