import React from 'react';
import { Grid } from '@material-ui/core';
import SelectItem from './SelectItem';
const ListSelectItem = (props) => {
    return (
        <Grid container style={{margin:"2em 0em"}}>
            {props.list.map((item, index) => 
            <SelectItem 
                background={props.background}
                color={props.color}
                index={index}
                selected={index === props.selected} 
                key={index}
                select={props.select}
                label={item}
                defaultColor={props.defaultColor}
                defaultBackground={props.defaultBackground}
                />
            )
            }
        </Grid>
    )
};

export default ListSelectItem;
