import React from 'react';
import { Grid, TextField } from '@material-ui/core';


const PasswordStep = (props) => (
    <Grid>
        <TextField 
                id="user_mail"
                label="Senha"
                margin="dense"
                fullWidth
                type="password"
                autoComplete="off"
                onChange={props.changeData('password')}
                style={{marginTop: 40, marginBottom: 40}}
                autoFocus
                defaultValue={props.data.password}
            />
            <TextField 
                id="user_phone"
                label="Confirme sua Senha"
                margin="dense"
                type="password"
                fullWidth
                autoComplete="off"
                onChange={props.changeData('password_confirmation')}
                style={{marginBottom: 40}}
                defaultValue={props.data.password_confirmation}
            />
    </Grid>
);

export default PasswordStep;
