import React from 'react';
import { Grid, TextField, MenuItem } from '@material-ui/core';
import CustomSelects from '../CustomSelects/CustomSelects';

import MaskedInput from 'react-text-mask';

// import { Container } from './styles';

const cellphone = (props) => {
    const { inputRef , ...other} = props;
  
    return (
    <MaskedInput
        {...other}
        ref={ref => {
            inputRef(ref ? ref.inputElement : null);
        }}
        mask={['(',/\d/, /\d/, ')', /\d/ ,/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
        placeholderChar={'\u2000'}
    />
    )
  }

  const birthdate = (props) => {
    const { inputRef , ...other} = props;
  
    return (
    <MaskedInput
        {...other}
        ref={ref => {
            inputRef(ref ? ref.inputElement : null);
        }}
        mask={[/[0-3]/, /\d/, '/', /[0-1]/ ,/\d/,'/', /\d/, /\d/, /\d/, /\d/]}
        placeholderChar={'\u2000'}
    />
    )
  }

const CompleteRegisterStep = (props) => (
    <Grid>
         <TextField 
                id="user_phone"
                label="Celular"
                margin="dense"
                fullWidth
                autoComplete="off"
                onChange={props.changeData('cellphone')}
                style={{marginBottom: 40}}
                InputProps={{
                    inputComponent: cellphone
                }}
                defaultValue={props.data.cellphone}
                error={props.data.telephone_error}
                helperText={props.data.telephone_error ? 'O celular informado já está vinculado a um usuário' : ''}
        />
        <TextField 
            id="user_birthdate"
            label="Data de Nascimento"
            margin="dense"
            fullWidth
            autoComplete="off"
            onChange={props.changeData('birthdate')}
            style={{marginBottom: 40}}
            InputProps={{
                inputComponent: birthdate
            }}
            defaultValue={props.data.birthdate}
        />
        <Grid style={{marginBottom: 40}}>
            <CustomSelects 
                value={props.data.sex} 
                change={props.changeData('sex')}
                label={"Selecione seu Gênero"}
                >
                    <MenuItem value={'M'}>Masculino</MenuItem>
                    <MenuItem value={'F'}>Feminino</MenuItem>
            </CustomSelects>
        </Grid>
    </Grid>
);

export default CompleteRegisterStep;
