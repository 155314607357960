import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { subscribe_notifications } from './Auth/Auth';
import './index.css';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { GoogleOAuthProvider } from '@react-oauth/google';

const googleId = process.env.REACT_APP_GOOGLE_ID
const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || "#01bf9b"
const secondaryColor = process.env.REACT_APP_SECONDARY_COLOR || "#fff"

const theme = createMuiTheme({
    palette: {
        primary: {
            main: primaryColor,
            contrastText: secondaryColor,// The text color to use
        },
      secondary: {
        main: primaryColor,
        contrastText: secondaryColor// The text color to use
      },
    },
  });
ReactDOM.render(
    <GoogleOAuthProvider clientId={googleId}>
        <MuiThemeProvider theme={theme}>
            <App />
        </MuiThemeProvider>
    </GoogleOAuthProvider>
    ,document.getElementById('root'));


serviceWorker.register();

let OneSignal = window.OneSignal || [];
OneSignal.push(function() {
    var OneSignal = window.OneSignal || [];
        OneSignal.push(function() {
            OneSignal.init({
                appId: process.env.REACT_APP_PROD_ONE_SIGNAL_APP_ID,
                requiresUserPrivacyConsent: true,
            });
        });

    OneSignal.push(function() {
        // Occurs when the user's subscription changes to a new value.
        OneSignal.on('subscriptionChange', function (isSubscribed) {
            console.log("The user's subscription status has change, isSubscribed:", isSubscribed);
            OneSignal.getUserId( function(userId) {
                console.log("OneSignal User ID:", userId);
                subscribe_notifications(userId);
            });
        });
    });
  
});
