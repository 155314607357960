import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import HomeEstablishments from '../HomeEstablishments/HomeEstablishments';
import CustomBottomNavigation from '../CustomBottomNavigation/CustomBottomNavigation';

function userAccept() {
  let OneSignal = window.OneSignal || undefined;
  if(OneSignal === undefined) return;
  if(!localStorage.getItem('consent')){
     OneSignal.provideUserConsent(true);
     localStorage.setItem('consent',true);
  }

}
export default class Home extends Component {

  componentDidMount() {
    userAccept();
  }
  
  render() {
    return (
      <Grid>
        <HomeEstablishments />
        <CustomBottomNavigation start={0} />
      </Grid>
    )
  }
}
