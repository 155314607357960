import React, { Component } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { Validator } from '../../Helpers/Validator';
import { complete_register, exist } from '../../Auth/Auth';
import { Redirect } from 'react-router-dom';
import CompleteRegisterStep from '../Register/CompleteRegisterStep';

export default class CompleteProfile extends Component {
  state = {
      birthdate: "",
      formated_birthdate: "",
      sex: "0",
      cellphone: '',
      success: false,
      data: {}
  }

  constructor(props) {
      super(props);
      this.complete = new Validator();

  }

  getData = (name) => (e) => {
    let value = e.target.value;
    if (name === 'cellphone') {
      value = e.target.value.replace("(","").replace(")","").trim();
    }

    if (name === 'birthdate') {
      const birthdate = value.split('/');
      const formated_birthdate = `${birthdate[2]}-${birthdate[1]}-${birthdate[0]}`;
      this.setState({
        formated_birthdate
      })
      value = e.target.value.replace(/\//g, '').trim();
    }
    
    this.setState({
      [name]: value.toString()
    })
  }

  validate = async () => {
    const response = await exist(this.state);
    if (response.status === 200) {
      const exist = await response.json();

      if (exist.telephone) {
        this.setState({
          telephone_error: exist.telephone
        })
      }
      return exist.telephone;
    }
  }

  done = async () => {
      if (await this.validate()) return;
    
      const response = await complete_register(this.state);

      if (response.status === 200) {
          const { success } = await response.json();
          if (success === 'OK') {
            localStorage.setItem("complete_profile", true);
              this.setState({
                  success: true
              })
          }
          
      }
  }

  render() {
    if (this.state.success) return <Redirect to="/" />
    this.complete.setValues([
     {value: this.state.birthdate, min: 8, required: true},
     {value:this.state.cellphone, min: 11, max:11 , required:true}
    ])
    return (
        <Grid style={{padding: 10}}>
            <Typography variant="subtitle1">
                Complete seu cadastro*
            </Typography>
            <CompleteRegisterStep data={this.state} changeData={this.getData} />
           <Button disabled={!(this.complete.check() && this.state.sex !== '0')} fullWidth onClick={this.done} variant="contained" size="medium" color="secondary">
             Finalizar
            </Button>
        </Grid>
    )
  }
}
