import React from 'react';
import { Dialog, DialogTitle,DialogContent,DialogContentText, Button, DialogActions } from '@material-ui/core';
import Transition from '../Transition/Transition';

const ModalConfirm = (props) => (
    <Dialog
        open={props.open}
        onClose={props.close}
        TransitionComponent={Transition}
    >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
            <DialogContentText id="text-modal">
              {props.text}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.close} color="primary">
              Agora não
            </Button>
            <Button onClick={props.action} color="primary">
              Ok
            </Button>
          </DialogActions>
    </Dialog>
);

export default ModalConfirm;
