import { base_url } from "./Base";

export const get_customer_in_company = (companyid,customerid) => {
    return fetch(`${base_url}companies/${companyid}/customer/${customerid}`,{
        headers: {
          "Content-Type" : "application/json",
          "Accept" : "application/json",
          "Authorization" : localStorage.getItem("token")        
        },
        method: "GET"
      });
  }

export const update_user = (body) => {
  return fetch(`${base_url}user`,{
    headers: {
      "Content-Type" : "application/json",
      "Accept" : "application/json",
      "Authorization" : localStorage.getItem("token")        
    },
    method: "PUT",
    body: JSON.stringify(body)
  });
}