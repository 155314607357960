import React  from 'react';
import Routes from './routes';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';

const App = () => (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
         <Routes />
    </MuiPickersUtilsProvider>
);

export default App;
