import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Redirect } from "react-router-dom";
import Header from '../Header/Header';


export default class Success extends Component {
  render() {
    if(!this.props.location.state) return <Redirect to="/" />
    return (
      <div className="success">
        <Header title="Sucesso" backbutton={this.props.history} />
        <Paper elevation={1} style={{padding: "1em", margin: 10}}>
          <Typography variant="subtitle2" component="h3">
            {this.props.location.state.success}
          </Typography>
          <Typography variant="caption">
            {this.props.location.state.info}
          </Typography>
        </Paper>
      </div>
    )
  }
}
