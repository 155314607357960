import React, { Component } from 'react';
import CustomBottomNavigation from '../CustomBottomNavigation/CustomBottomNavigation';
import { Grid } from '@material-ui/core';
import UserProfile from '../UserProfile/UserProfile';


export default class Profile extends Component {


  render() {
    return (
    <Grid>
        <UserProfile />
        <CustomBottomNavigation start={2} />
    </Grid>
    );
  }
}
