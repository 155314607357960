import React from 'react';
import { isAuthenticated, complete_profile } from './Auth/Auth';

import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Login from './Components/Pages/Login';
import Register from './Components/Pages/Register';
import Success from './Components/Pages/Success';
import Password from './Components/Pages/Password';
import Settings from './Components/Pages/Settings';
import Scheduling from './Components/Pages/Scheduling';
import MySchedulesPage from './Components/Pages/MySchedulesPage';
import Home from './Components/Pages/Home';
import Profile from './Components/Pages/Profile';
import Schedule from './Components/Pages/Schedule';
import Verify from './Components/Pages/Verify';
import CompleteProfile from './Components/Pages/CompleteProfile';
import ForgotPassword from './Components/Pages/ForgotPassword';
import ResetPassword from './Components/Pages/ResetPassword';



const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => {
        if(isAuthenticated()) {
            if(!complete_profile()) return <Redirect to={{pathname: '/requirement/profile', state: { from: props.location }}} />
            return <Component {...props} />
        }

        return <Redirect to={{ pathname: '/' ,state: { from: props.location }}}/>
    }} />
);

const AuthenticatedRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        isAuthenticated() ? (
            <Redirect to={{ pathname: '/app', state: { from: props.location } }} />
        ) : (
                <Component {...props} />
            )
    )} />
);

const RequirementsPrivateRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={props => {
        if(isAuthenticated()) 
            if(!complete_profile()) return <Component {...props} />
    
    return <Redirect to={{ pathname: '/' ,state:{ from: props.location }}}/>
            
    }} />
);

const Routes = () => (
    <BrowserRouter>
        <Switch>
            <AuthenticatedRoute exact path="/" component={Login} />
            <AuthenticatedRoute path="/register" component={Register} />
            <AuthenticatedRoute path="/success" component={Success} />
            <AuthenticatedRoute path="/verify/:token" component={Verify} />
            <AuthenticatedRoute path="/forgot_password" component={ForgotPassword} />
            <AuthenticatedRoute path="/reset_password/:token" component={ResetPassword} />
            <PrivateRoute path="/establishment/:id/services" component={Scheduling} />
            <PrivateRoute path="/services/:sid/schedule/:id" component={Schedule} />
            <PrivateRoute path="/my_schedules" component={MySchedulesPage} />
            <PrivateRoute path="/settings" component={Settings} />
            <PrivateRoute path="/password" component={Password} />
            <PrivateRoute path="/profile" component={Profile} />
            <PrivateRoute path="/app" component={Home} />
            <RequirementsPrivateRoute path="/requirement/profile" component={CompleteProfile} />
        </Switch>
    </BrowserRouter>
);

export default Routes;