import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import {Grid, Icon} from '@material-ui/core';

const style = {
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
    },
    input: {
      marginLeft: 8,
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      width: 1,
      height: 28,
      margin: 4,
    },
  };

const CustomInputSearch = (props) => {
    const { classes } = props;
    return (
        <form onSubmit={props.search}>
            <Grid>
                <Paper className={classes.root} elevation={1}>
                    {/* <IconButton className={classes.iconButton} aria-label="Menu">
                        <Icon>filter_list</Icon>
                    </IconButton> */}
                    <InputBase onChange={props.inputChange} className={classes.input} placeholder="Buscar estabelecimento" />
                    <Divider className={classes.divider} />
                    <IconButton onClick={props.search} className={classes.iconButton} aria-label="Search">
                        <Icon >search</Icon>
                    </IconButton>
                </Paper>
            </Grid>
        </form>
    )
}

export default withStyles(style)(CustomInputSearch);