import React from 'react';
import { Paper } from '@material-ui/core';


const Blocks = (props) => (
        <Paper onClick={props.onClick} elevation={1} 
               style={{padding:5, margin: 5, background: props.background ? props.background : ''}}>
            {props.children}
        </Paper>
);

export default Blocks;
