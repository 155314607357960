import { base_url } from "./Base";

export const get_settings = () => {
    return fetch(`${base_url}users/settings`,{
      headers: {
        "Content-Type" : "application/json",
        "Accept" : "application/json",
        "Authorization" : localStorage.getItem("token")        
      },
      method: "GET"
    });
}

export const set_settings = (settings) => {
  return fetch(`${base_url}users/settings`, {
      headers: {
         "Content-Type" : "application/json",
         "Accept" : "application/json",
         "Authorization" : localStorage.getItem("token")        
      },
      method: "PATCH",
      body: JSON.stringify({expose: settings.expose})
  });
}