import React, { PureComponent } from 'react';
import {Grid, Button} from '@material-ui/core';
import {DatePicker} from "material-ui-pickers";
import ListSelectItem from '../SelectItem/ListSelectItem';
import { free_schedule } from '../../Services/Schedule';
import { format } from 'date-fns';
import { reschedule } from '../../Services/SchedulingItem';

const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || "#01bf9b"
const secondaryColor = process.env.REACT_APP_SECONDARY_COLOR || "#fff"

class Reschedule extends PureComponent {
    state = {
        selectedDate: new Date(),
        selectedSchedule: null,
        free: []
    }

    getFreeSchedule = async (scheduleid,serviceid, schedule_date = this.state.selectedDate) => {

        let date = format(schedule_date, 'yyyy-MM-dd');
        let response = await free_schedule(scheduleid,date,serviceid);
        let schedules = await response.json();
        return schedules.schedule_free;
    }

    handleDateChange = async (selectedDate) => {

        let free = await this.getFreeSchedule(this.props.scheduleid,this.props.serviceid,selectedDate);
        this.setState({
            free,
            selectedDate,
            selectedSchedule: null
        })
    }

    async componentDidMount() {
        let free = await this.getFreeSchedule(this.props.scheduleid,this.props.serviceid);
        this.setState({free});
    }

    handleReschedule = async () => {
        let { id } = this.props;
        let {selectedDate,  selectedSchedule , free} = this.state;
        let date = format(selectedDate, 'yyyy-MM-dd') + ' '+free[selectedSchedule];
        let response = await reschedule(id, {date});
        let data = await response.json();
        this.props.eventReschedule(data);
    }

    render() {
        let { selectedDate,selectedSchedule , free  } = this.state;
        let now = new Date();
        return (
            <Grid item xs={12} style={{margin: 5}} >
                <DatePicker 
                    fullWidth
                    disablePast
                    label={`Data de agendamento`}
                    maxDate={now.setDate(now.getDate() +7)}
                    format={"dd/MM/yyyy"} 
                    value={selectedDate} 
                    onChange={this.handleDateChange} 
                />

                <ListSelectItem 
                    color={secondaryColor}
                    background={primaryColor}
                    selected={selectedSchedule} 
                    select={(selectedSchedule) => () => this.setState({selectedSchedule}) } 
                    list={free}  
                />
                <Button style={{ color:selectedSchedule !== null ? primaryColor : ''}} 
                        fullWidth 
                        variant="outlined"
                        onClick={this.handleReschedule}
                        disabled={selectedSchedule === null}>
                    Agendar {free[selectedSchedule]}
                </Button>
            </Grid>
        )
    }
}
    


export default Reschedule;